import { render, staticRenderFns } from "./Wrapper.vue?vue&type=template&id=5e928e12&scoped=true"
import script from "./Wrapper.vue?vue&type=script&lang=js"
export * from "./Wrapper.vue?vue&type=script&lang=js"
import style0 from "./Wrapper.vue?vue&type=style&index=0&id=5e928e12&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e928e12",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VContent } from 'vuetify/lib/components/VContent';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VContainer,VContent,VIcon,VLayout,VSpacer})
