<template>
    <div id="wrapper_main" class="pt-3">

        <v-card class="mt-7 mb-3 mx-4">
            <v-container class="px-1">
                <v-content>
                    <store-toolbar :user="user" :icon_size="icon_size" style="margin-top:-55px;"></store-toolbar>

                    <v-layout id="main_toolbar" justify-center row :style="{marginLeft: icon_size + 'px', marginTop: '-60px'}">
                        <store-search :categories="categories"
                                      @search-init-search="searchInitSearch">
                        </store-search>

                        <v-spacer></v-spacer>

                        <v-btn text fab rounded to="/cart" class="mx-4">
                            <v-icon color="tertiary">mdi-cart</v-icon>
                        </v-btn>
                    </v-layout>


                    <router-view name="router_wrapper_main"
                                 @navigate-to-search="navigateToSearch"
                                 @navigate-to-category="navigateToCategory"
                                 @navigate-to-product="navigateToProduct"
                                 @navigate-to-checkout="navigateToCheckout"
                                 @navigate-to-storefront="navigateToStoreFront"

                                 @get-cart-products="getCart"
                                 :cart_items="cart_items"
                                 :user="user">
                    </router-view>


                </v-content>
            </v-container>
        </v-card>

    </div>
</template>

<script>
    export default {
        name: "main_store_wrapper",
        data: () => ({
            icon_size: 125,

            title: null,
            search_term: '',

            categories: [],
            cart_items: [],

            search_parameters:{
                search_text: '',
                search_category: undefined,
                search_filters: [],
                tags: []
            },

        }),
        computed: {
            user: function (){
                return this.$store.getters.user;
            },
        },
        created () {
            this.getCategories();
        },
        mounted () {
            this.getCart();
        },
        methods: {
            async getCategories(){
                this.categories = await this.$http
                    .get('/store/category')
                    .then(data => data.data);
                console.log(this.categories);
                console.log("Categories Loaded");
            },

            async getCart() {
                if (!!Object.keys(this.user).length)
                    this.cart_items = await this.$http
                        .get('/store/cart')
                        .then(data => data.data);
            },
            searchInitSearch(){
                //Load the searched-product component on the router with the correct parameters
                this.$router.push({ name: 'user', params: { userId: '123' } })
            },
            navigateToStoreFront(){
                // named route
                this.$router.push({ name: 'Store'})
            },
            navigateToSearch(category){
                // named route
                this.$router.push({ name: 'user', params: { userId: '123' } })
            },
            navigateToCategory(category){
                this.$router.push({ name: 'ProductSearch', params: { category_id: category, search_name:'undefined' } })

            },
            navigateToProduct(erp_item_number){
                // named route
                //console.log(erp_item_number);
                this.$router.push({ name: 'Product', params: {erp_item_number: erp_item_number} });
            },
            navigateToCheckout(product){
                // named route
                this.getCart();
                this.$router.push({ name: 'Cart' });
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../styles/main/index.scss';

    #wrapper_main{
        .main_content{
            min-height: calc(100vh - 110px);

            #main_toolbar{
                margin-left: 130px;
                height:60px;
            }

        }
    }

</style>
